import { UPDATE_STATE, LOGOUT } from '../state/constants';

export const isAuthenticated = () => !!localStorage.getItem("token");

export const authorization = () => `Bearer ${localStorage.getItem("token")}`;

const REFRESH_TOKEN_TIMEOUT = 14 * 60 * 1000 + 40 * 1000;
const REFRESH_TOKEN_TIMER = "refresh_token_timer"

export const login = async (creds) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
    body: JSON.stringify(creds),
    method: "POST",
    credentials: "include",
  })
  if (!response.ok) {
    throw await response.json();
  }
  const { response: user } = await response.json();
  localStorage.setItem("token", user.jwt);
  scheduleTokenRefresh()
}

export const logout = (dispatch = () => {}) => {
  localStorage.removeItem("token");
  clearTokenRefresh()
  dispatch({
    type: LOGOUT
  })
  window.location = "/login";
}

export const loadUserData = async (dispatch) => {
  const response = await fetchWrapper("/profile")
  if (!response.ok) {
    console.error(await response.text())
    return logout(dispatch);
  }
  const { response: user } = await response.json();
  scheduleTokenRefresh();
  if(process.env.REACT_APP_INTERCOM_APP_ID) {
    let config = user.UserConfig.IntercomConfig
    config.language_override= 'fr'
    window.Intercom("boot", config);
  }
  dispatch({ type: UPDATE_STATE, payload: { user } })
}

export const refreshToken = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/token/refresh`, {
    method: "POST",
    credentials: "include",
    headers: { authorization: authorization() },
  });
  if(!response.ok) {
    const err = new Error("Cannot refresh token")
    console.error(err);
    return err;
  }
  const { response: user } = await response.json();
  localStorage.setItem("token", user.jwt);
  scheduleTokenRefresh();
  return null
}

export const fetchWrapper = async (resource, init={}) =>  {
  let [response, err] = await simpleFetch(resource, init)
  if (!err) return response;
  if (err.includes("expired")) {
    err = await refreshToken();
    if(err) {
      logout()
    }
    [response, err] = await simpleFetch(resource, init)
    if(!err) return response;
  }
  logout();
}

async function simpleFetch (resource, init) {
  let response = await fetch(`${process.env.REACT_APP_API_URL}${resource}`, withAuth(init))
  if (response.ok || response.status !== 401){
    return [response, ""];
  }
  const err = await response.text()
  return [null, err]
}

function withAuth(init) {
  return {
    ...init,
    headers: {
      ...init.headers,
      authorization: authorization()
    }
  }
}

function scheduleTokenRefresh() {
  clearTokenRefresh()
  let timer = setTimeout(() => { refreshToken() }, REFRESH_TOKEN_TIMEOUT)
  localStorage.setItem(REFRESH_TOKEN_TIMER, String(timer))
}

function clearTokenRefresh() {
  let timer = Number(localStorage.getItem(REFRESH_TOKEN_TIMER))
  if (timer) {
    clearTimeout(timer)
    localStorage.removeItem(REFRESH_TOKEN_TIMER)
  }
}
