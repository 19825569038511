import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import "./PageSubtitle.css";

const PageSubtitle = (props) => {

  const history = useHistory();

  const goBack = () => {
    history.goBack()
  }

  return (
    <Row className="pagesubtitle-container">
      <Col xs={1} className="pagesubtitle-back">
        {props.canGoBack && <FontAwesomeIcon icon={faArrowLeft} onClick={goBack} size="lg" className="pagesubtitle-icon" />}
      </Col>
      <Col xs={6}>
        {props.children}
      </Col>
      <Col xs={5}>
        {props.tools}
      </Col>
    </Row>
  )
};


PageSubtitle.defaultProps = {};

export default PageSubtitle;
