import React, { useCallback, useEffect, useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PageSpinner from "../PageSpinner";
import CustomTable from "../CustomTable";
import PageSubtitle from "../PageSubtitle";
import {
  loadRequestsHistory
} from "../../utilities/requests";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { GlobalStateContext } from "../../state";

import "./History.css";

dayjs.extend(customParseFormat);

const groupFormat = "DD-MM-YYYY";

const isRed = (request) => {
  const redStatuses = ["canceled", "expired"];
  return redStatuses.includes(request.Job.status);
};

const getPrice = ({ Job: { pricing }, PricingInfo }) => {
  if (PricingInfo) return PricingInfo.price;
  return pricing;
};

const History = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [state] = useContext(GlobalStateContext);

  const [requests, setRequests] = useState(null);
  const [loadedCount, setLoadedCount] = useState(0);
  const [xTotal, setXTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage] = useState(20);
  const [pages, setPages] = useState(1);
  const [stagedFilterText, setStagedFilterText] = useState("");
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    document.title = `${t("history")} | XL EDS`;
  });

  const groupRequests = (loadedRequests) => {
    setRequests(
      loadedRequests.reduce((grouped, request) => {
        const day = dayjs(request.Job.ended_at).format(groupFormat);
        return {
          ...grouped,
          [day]: [...(grouped[day] || []), request],
        };
      }, {})
    );
  };

  const reloadRequests = useCallback(
    async (p) => {
      setRequests(null);
      const args = [p, perPage, filterText];
      const { requests: loadedRequests = [], total } =
        await loadRequestsHistory(...args);
      setXTotal(total);
      groupRequests(loadedRequests);
      setLoadedCount(loadedRequests.length);
      setPages(Math.ceil(total / perPage));
    },
    [perPage, filterText]
  );

  useEffect(() => {
    reloadRequests(page);
  }, [reloadRequests, page]);

  const open = (id) => () => {
    history.push(`/jobs/${id}`);
  };

  const submitFilter = (e) => {
    e.preventDefault();
    setFilterText(stagedFilterText);
  };

  const clearFilter = (e) => {
    setStagedFilterText("");
    setFilterText("");
  };

  return (
    <Container fluid>
      {!requests && <PageSpinner></PageSpinner>}
      {requests && !loadedCount && !filterText.length && (
        <div className="history-empty">
          <img src="no-history.svg" alt="no history" />
          <h3>{t("noHistoryTitle")}</h3>
          <p>{t("noHistoryDescription")}</p>
          <Button variant="info" as={Link} to="/">
            {t("requestDelivery")}
          </Button>
        </div>
      )}
      {requests && (loadedCount || filterText.length > 0) && (
        <div>
          <PageSubtitle>
            <h6>
              {xTotal} {t("delivery", { count: xTotal })}
            </h6>
          </PageSubtitle>
          <br />
          <Form onSubmit={submitFilter}>
            <Row>
              <Col xs={{ offset: "1", span: "auto" }}>
                <InputGroup>
                  <Form.Control
                    id="searchInArray"
                    type="text"
                    placeholder={t("searchInArray")}
                    value={stagedFilterText}
                    onChange={(e) => {
                      setStagedFilterText(e.target.value);
                    }}
                  />
                  <InputGroup.Append className="history-clearicon">
                    <InputGroup.Text
                      onClick={clearFilter}
                      disabled={!stagedFilterText.length}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col>
                <Button variant="info" onClick={submitFilter}>
                  {" "}
                  {t("filter")}{" "}
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <CustomTable>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("clientReference")}</th>
                    <th>{t("date")}</th>
                    <th>
                      {t("firstName")} et {t("lastName")}
                    </th>
                    <th>{t("pickup")}</th>
                    <th>
                      {t("firstName")} et {t("lastName")}
                    </th>
                    <th>{t("dropoff")}</th>

                    {state.user.UserConfig.ShowPricing && <th>{t("price")}</th>}
                  </tr>
                </thead>
                {requests.length === 0 && (
                  <tbody>
                    <tr>
                      <td colSpan="5">{t("noHistorySearchResult")}</td>
                    </tr>
                  </tbody>
                )}
                {Object.keys(requests)
                  .sort((a, b) =>
                    dayjs(a, groupFormat).isBefore(dayjs(b, groupFormat))
                      ? 1
                      : -1
                  )
                  .map((day) => (
                    <tbody key={day}>
                      {requests[day].map((request, index) => (
                        <tr
                          key={index}
                          className="history-item"
                          disabled={isRed(request)}
                          onClick={open(request.Job.id)}
                        >
                          <td>#{request.Job.id}</td>
                          <td>#{request.Job.deliveries[0].client_reference}</td>
                          <td>
                            {dayjs(request.Job.ended_at).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </td>
                          <td>
                            {
                              request.Job?.deliveries[0].pickup.contact
                                .firstname
                            }{" "}
                            {request.Job?.deliveries[0].pickup.contact.lastname}
                          </td>
                          <td>
                            {
                              request.Job?.deliveries[0].pickup.address
                                .formatted_address
                            }
                          </td>
                          <td>
                            {
                              request.Job?.deliveries[0].dropoff.contact
                                .firstname
                            }{" "}
                            {
                              request.Job?.deliveries[0].dropoff.contact
                                .lastname
                            }
                          </td>
                          <td>
                            {
                              request.Job.deliveries[0].dropoff.address
                                .formatted_address
                            }
                            &nbsp;
                            {request.Job.deliveries.length > 1 && (
                              <span>
                                {t("otherDeliveriesCount", {
                                  count: request.Job.deliveries.length - 1,
                                })}
                              </span>
                            )}
                          </td>
                          {state.user.UserConfig.ShowPricing && (
                            <td>€{getPrice(request).price_tax_included}</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  ))}
              </CustomTable>
            </Col>
          </Row>
          <br />
          <Row>
            <Col></Col>
            <Col xs="auto">
              <Pagination
                count={pages}
                page={page}
                onChange={(e, v) => {
                  setPage(v);
                }}
              />
            </Col>
            <Col xs={1}></Col>
          </Row>
          <br />
        </div>
      )}
    </Container>
  );
};

History.defaultProps = {};

export default History;
