export default {
  translation: {
    newDelivery: "Nouvelle livraison",
    delivery: "Livraison",
    delivery_plural: "Livraisons",
    address: "Adresse",
    address_plural: "Adresses",
    addressDetails: "Détails de l'adresse",
    addressBook: "Carnet d'adresses",
    addNewAddress: "Ajouter nouvelle adresse",
    pickup: "Retrait",
    dropoff: "Livraison",
    firstName: "Prénom",
    lastName: "Nom",
    searchAddress: "Rechercher une adresse",
    phone: "Téléphone",
    invalidPhone: "Veuillez entrer un numéro valid",
    missingPhoneLength: "Il manque {{number}} chiffres à ce numéro",
    email: "Email",
    invalidEmail: "Veuillez saisir un email valide",
    courierDetails: "Informations pour le livreur",
    detailsPlaceholder: "étage ou autres infos additionnelles",
    orderDetails: "Détails de la commande",
    orderId: "Numéro de commande",
    orderInfos: "Contenu de la commande",
    distance: "Distance",
    submitRequest: "Commander",
    for: "pour",
    creatingRequest: "Création de la commande",
    vehicleTypeTitle: "Selectionnez un véhicule",
    walk: "Piéton",
    bike: "Vélo",
    motorbike: "Scooter",
    car: "Voiture",
    cargobike: "Cargo Bike",
    van: "Van",
    truck: "Camion",
    orderDateTitle: "Quand ?",
    now: "Maintenant",
    later: "Plus tard",
    date: "Date",
    timeSlot: "Créneau horaire",
    requestDelivery: "Demander une livraison",
    ongoing: "En cours",
    scheduled: "Programmées",
    history: "Historique",
    settings: "Réglages",
    credit: "Crédit",
    logout: "Se déconnecter",
    login: "Connexion",
    "Unauthorized, credentials not valid":
      "Non autorisé, identifiants non valides",
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    loginTitle: "Connectez-vous à XL EDS",
    noOngoingTitle: "Aucune livraison en cours",
    noOngoingDescription: "Commandez votre première livraison maintenant",
    searching: "À la recherche d'un livreur",
    in_progress: "Livraison dans {{left}} minutes",
    noScheduledTitle: "Aucune livraison programmée",
    noScheduledDescription: "Vos livraisons programmées apparaîtront ici",
    rating: "Évaluation",
    invoice: "Facture",
    downloadInvoice: "Télécharger Facture",
    price: "Prix",
    noHistoryTitle: "Aucun historique",
    noHistoryDescription: "Vous n'avez demandé aucune livraison",
    saveAddressLabel: "Sauvegarder cette adresse",
    noNameAddress: "Adresse sans nom de client",
    addressAlreadySaved:
      "Cette adresse figure déjà dans votre carnet d'adresses",
    "The address can't be geocoded": "Cette adresse n'est pas assez précise",
    "This location is out of range":
      "Désolé, XL EDS ne peut pas livrer à cette adresse",
    "Contact phone is required":
      "Cette addresse n'est pas assez précise. Veuillez entrer un numéro de téléphone",
    submit: "Enregistrer",
    cancel: "Annuler",
    yesCancel: "Oui annuler",
    cancelled: "Annulée",
    cancelAlert: "Vous êtes sur le point d'annuler une livraison vers",
    back: "Retour",
    delete: "Supprimer",
    options: "Options",
    pricingCriterias: "Critères de tarification",
    successToast: "Livraison créée avec succès, redirection en cours..",
    cancelHelp:
      "Aidez-nous à améliorer les services de XL EDS et dites-nous la raison de l'annulation",
    cantCancel: "Impossible d'annuler cette livraison",
    address_error: "Erreur d'adresse",
    courier_issue: "Refus de la livraison par le livreur",
    customer_cancellation_requested: "Annulation par le client",
    duplicate_job: "Demande dupliquée",
    incorrect_package: "Paquet incorrect",
    no_supply: "Pas de livreur disponible",
    other: "Autre",
    package_damaged: "Paquet endommagé",
    package_not_ready: "Paquet non prêt",
    pu_closed: "Retrait clos",
    technical_issue: "Problème technique",
    subtotal: "Prix HT",
    taxedPrice: "Prix TTC",
    tax: "Taxe",
    total: "Total",
    requestIsScheduled: "Cette livraison a été programmée, tenez-vous prêt à ",
    requestIsCanceled: "Vous avez annulé cette livraison.",
    priceNotSupported: "Ces options ne correspondent à aucun prix",
    validatingAddress: "Validation d'adresse en cours",
    clientReference: "Référence client",
    filterByClientRef: "Filtrer par référence client",
    filter: "Filtrer",
    "A destination address is not in a valid delivery area":
      "La destination n'est pas valide",
    "Unable to save record": "Erreur d'enregistrement",
    tax_excluded: "HT",
    tax_included: "TTC",
    noHistorySearchResult: "Aucun résultat de recherche",
    reloadHistory: "Recharger l'historique",
    addDropOff: "Ajouter une livraison",
    editDelivery: "Modifier livraison",
    otherDeliveriesCount: "(+{{count}} autre livraison)",
    otherDeliveriesCount_plural: "(+{{count}} autres livraisons)",
    errorLoadingPricingAttributes:
      "Erreur de chargement du prix, vérifiez la configuration du prix",
    cancelComment: "Commentez votre expérience",
    noSignature: "Pas de signature",
    shortPending: "Nous recherchons le meilleur coursier.",
    mediumPending: "Veuillez patienter, nous continuons de chercher.",
    longPending: "Nous analysons les meilleures options possibles.",
    highPending: "N'annulez pas, vous perdriez votre priorité !",
    veryHighPending: "Notre algorithme continue de chercher une solution.",
    eternityPending:
      "Nous mettons tout en oeuvre pour trouver un coursier. Veuillez patienter...",
    picking: "Retrait dans {{left}} minutes.",
    pickupNow: "Retrait mainteneant.",
    delivering: "Livraison dans {{left}} minutes.",
    dropoffNow: "Livraison Maintenant.",
    "Insufficient funds for client  Please provide a Credit Card":
      "Fonds insuffisants pour le client. Veuillez fournir une carte de crédit.",
    "context deadline exceeded (Client Timeout exceeded while awaiting headers)":
      " Délai dépassé lors de l'attente des en-têtes.",
    "Same Order ID can't be used in multiple destinations":
      "Le même numéro de commande ne peut pas être utilisé dans plusieurs destinations.",
    packageDescriptionAvailableCharacters:
      "Il vous reste {{nbrAvailableCharacters}} charactères",
    packageDescriptionLimitExceeded:
      "vous avez dépassé le nombre maximum de caractères autorisé",
    wrongNumberFormat: "format incorrect, vous devez entrer un nombre valide",
    searchInArray: "Chercher dans le tableau",
    generalConditions: "Conditions Générales",
    termsOfService: "Conditions Générales d'utilisation du site.",
    gcu: "CGU",
    siteUsesOnlyMandatoryCookies: "Ce site utilise seulement des cookies obligatoires",
    cookiesMoreInfoTitle: "Cookies strictement nécessaires - Toujours actifs",
    cookiesMoreInfoText: "Ces cookies sont nécessaires au bon fonctionnement de la Plateforme et ne peuvent pas être désactivés sur nos systèmes. En général, ils sont configurés pour répondre aux actions que vous effectuez lorsque vous demandez des services, tels que l'ajustement de vos préférences en matière de confidentialité, la connexion à votre compte ou le remplissage de formulaires. Vous pouvez configurer votre navigateur pour bloquer ces cookies ou vous alerter de leur présence ; cependant, certaines parties de la plateforme ne fonctionnent pas sans eux.",
    cookiesClose: "Ok, fermer",
    learnMore: "En savoir plus",
    gcuAndLegalNotice: "CGU et mentions légales",
    legalNotice: "Mentions légales",
    legalNoticeText: "Société par Actions Simplifiée au capital de 4.100.000 euros\nRCS Paris 847 535 986\nSiège social : 43/45 avenue de Clichy, 75017 PARIS\nTéléphone : [0176420827]\nNuméro de TVA intracommunautaire : [FR 29847535986]"
  },
};
