import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Address from '../Address/Address';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { toastOptions } from '../../state/constants';
import { editDelivery } from '../../utilities/requests';

import "./RequestEdit.css"
import { useTranslation } from 'react-i18next';

const EditRequest = (props) => {

  const { t } = useTranslation();

  const [isSubmittingEdit, setIsSubmittingEdit] = useState(false);

  const closeEdit = () => props.setShowEdit(false);

  const submitEdit = async () => {
    try {
      setIsSubmittingEdit(true);
      const { isValid, ...value } = props.editedAddress;
      await editDelivery(props.orderId, props.editedDeliveryId, value, props.editedType);
      closeEdit();
      props.reloadRequests();
    } catch (error) {
      toast.error("Une erreur est survenue", toastOptions);
    } finally {
      setIsSubmittingEdit(false);
    }
  }

  return (
    <div>
        <Modal show={props.showEdit} onHide={closeEdit} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>{props.editedAddressText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Address
                id="pickup-component"
                query={props.editedAddress}
                setter={props.setEditedAddress}
                hideSaveOption={true}
                hideSavedAddresses={true}
                noTitle
                hideAddress={true}
                showDetails={props.showEditDetails}
                noCheck={true}
            />
            </Modal.Body>
            <Modal.Footer>
            <Container>
                <Row>
                <Col></Col>
                <Col xs="auto">
                    <Button variant="outline-secondary" onClick={closeEdit}>
                    {isSubmittingEdit && <FontAwesomeIcon icon={faSpinner} pulse />}
                    {!isSubmittingEdit && t("cancel")}
                    </Button>
                    &nbsp;
                    <Button variant="info" onClick={submitEdit} disabled={!props.editedAddress.isValid}>
                    {isSubmittingEdit && <FontAwesomeIcon icon={faSpinner} pulse />}
                    {!isSubmittingEdit && t("submit")}
                    </Button>
                </Col>
                </Row>
            </Container>
            </Modal.Footer>
        </Modal>
        <ToastContainer transition={Slide} />
    </div>
  )
};

EditRequest.propTypes = {
};

export default EditRequest;
