import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEllipsisH, faPen, faTimes } from '@fortawesome/free-solid-svg-icons'
import RequestCanceller from "../RequestCanceller";
import RequestEdit from "../RequestEdit";
import { initMap } from '../../utilities/map';
import { loadRequests } from '../../utilities/requests';
import { GlobalStateContext } from '../../state';
import dayjs from "dayjs";

import './ongoing.css';

const getTimeProgress = (jobCreatedAt, deliveriesTime) => {
  const now = dayjs();
  const latest = deliveriesTime.reduce((supposed, current) => current.isAfter(supposed) ? current : supposed , deliveriesTime[0])
  if(now.isAfter(latest)) {
    return {timeLeft: 0, progress: 100}
  };

  const left = latest.diff(now, "minute");
  const duration = latest.diff(jobCreatedAt, "minute");
  const spent = duration - left;
  const progress = Math.round(spent / duration * 100);

  return {timeLeft: left, progress: progress};
}

const getDeliveringProgress = (jobCreatedAt, currentDeliveries) =>  {
  const progressTime = getTimeProgress(jobCreatedAt, currentDeliveries.map(delivery => dayjs(delivery.eta.dropoff)));
    if (progressTime.progress === 100){
      return {msg: "dropoffNow", variant: "success", timeProgress: progressTime};
    }

    return {msg: "delivering", variant: "success", timeProgress: progressTime};
}

const getPickingProgress = (jobCreatedAt, currentDeliveries) =>  {
  const progressTime = getTimeProgress(jobCreatedAt, currentDeliveries.map(delivery => dayjs(delivery.eta.pickup)));
  if (progressTime.progress === 100){
    return {msg: "pickupNow", variant: "warning", timeProgress: progressTime};
  }

  return {msg: "picking", variant: "warning", timeProgress: progressTime};
}

const getPendingProgress = (jobCreatedAt) => {
  const now = dayjs();
  const spent = now.diff(jobCreatedAt, "minute");
  if (spent < 3){
    return  {msg: "shortPending", variant: "info", timeProgress: {progress: 100}};
  }
  if (spent < 5){
    return  {msg: "mediumPending", variant: "info", timeProgress: {progress: 100}};
  }
  if (spent < 7){
    return  {msg: "longPending", variant: "info", timeProgress: {progress: 100}};
  }  
  if (spent < 9){
    return  {msg: "highPending", variant: "info", timeProgress: {progress: 100}};
  } 
  if (spent < 11){
    return  {msg: "veryHighPending", variant: "info", timeProgress: {progress: 100}};
  } 

  return  {msg: "eternityPending", variant: "info", timeProgress: {progress: 100}}; 
}
  

const getStatusProgress = job => {
  let currentDeliveries = job.deliveries.filter(delivery => ["delivering", "almost_delivering", "waiting_at_dropoff"].includes(delivery.status));
  if (currentDeliveries.length){
    return getDeliveringProgress(job.created_at, currentDeliveries);
  }

  currentDeliveries = job.deliveries.filter(delivery => ["picking", "almost_picking", "waiting_at_pickup"].includes(delivery.status));
  if (currentDeliveries.length){
    return getPickingProgress(job.created_at, currentDeliveries);
  }

  return getPendingProgress(job.created_at);
}


const getPrice = ({ Job: { pricing }, PricingInfo }) => {
  if (PricingInfo) return PricingInfo.price;
  return pricing;
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const Ongoing = () => {

  const { t } = useTranslation();

  const [state, { dispatch, googleLoaded }] = useContext(GlobalStateContext);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const pickUpMarker = useRef(null);
  const dropOffMarkers = useRef([]);
  const driverMarker = useRef(null);
  const displayedOrder = useRef({});
  const [displayedId, setDisplayedId] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditDetails, setShowEditDetails] = useState(true);
  const [editedAddress, setEditedAddress] = useState({});
  const [editedAddressText, setEditedAddressText] = useState("");
  const editedDeliveryId = useRef(null);
  const editedType = useRef(null);

  const reloadRequests = useCallback(() => {
    loadRequests(dispatch, "ongoing");
  }, [dispatch])

  useEffect(() => {
    document.title = `${t("ongoing")} | XL EDS`;
  });

  useEffect(() => {
    if (window.google && state.ongoing && state.ongoing.length && !map.current && mapContainer.current) 
    initMap(map, mapContainer)
  }, [googleLoaded, state.ongoing]);

  useEffect(() => {
    let timeout;
    const run = async () => { 
      await loadRequests(dispatch, "ongoing");
      timeout = setTimeout(run, 5000);
    }
    run();
    return () => { clearTimeout(timeout) }
  }, [dispatch])

  const fitBounds = () => {
    try {
      const bounds = new window.google.maps.LatLngBounds();
      [pickUpMarker.current, ...dropOffMarkers.current].forEach(marker => {
        if (marker) bounds.extend(marker.getPosition())
      })
      map.current.fitBounds(bounds);
      map.current.panToBounds(bounds);
    } catch (e) {
    }
  }

  const drawOrderMarkers = useCallback(async(job) => {
    if(pickUpMarker.current) pickUpMarker.current.setMap(null);
    dropOffMarkers.current.forEach(marker => { if(marker) marker.setMap(null) })
    const { pickup } = job.deliveries[0];
    const dropoffs = job.deliveries.map(delivery => delivery.dropoff);
    const pickupOptions = {
      position: { lat: pickup.latitude, lng: pickup.longitude },
      map: map.current,
      icon: "/pickup-marker.svg",
    };
    const dropoffsOptions = dropoffs.map(dropoff => ({
      position: { lat: dropoff.latitude, lng: dropoff.longitude },
      map: map.current,
      icon: "/dropoff-marker.svg",
    }));
    pickUpMarker.current = new window.google.maps.Marker(pickupOptions);
    dropOffMarkers.current = [];
    dropOffMarkers.current = dropoffsOptions.map(dropoffOptions => new window.google.maps.Marker(dropoffOptions));
    fitBounds();
    setDisplayedId(job.id);
  }, [])

  const drawDriver = useCallback((job) => {
    if(driverMarker.current) driverMarker.current.setMap(null) 
    const driver = job.driver;
    if(driver.status === "busy") {
      driverMarker.current = new window.google.maps.Marker({
        position: { lat: driver.latitude, lng: driver.longitude },
        map: map.current,
        icon: `/vehicle-markers/${job.transport_type}.svg`
      });
    }
  }, [])

  useEffect(() => {
    const ongoing = state.ongoing || [];
    if(ongoing.length && window.google) {
      const isStillHere = ongoing.some(o => o.Job.id === displayedOrder.current.id);
      if(!displayedOrder.current.id || !isStillHere) {
        displayedOrder.current = state.ongoing[0].Job;
        drawOrderMarkers(displayedOrder.current);
      }
      drawDriver(displayedOrder.current);
    }
  }, [drawDriver, drawOrderMarkers, state.ongoing])

  const select = job => e => {
    if(job.id === displayedOrder.current.id) return;
    displayedOrder.current = job;
    drawOrderMarkers(displayedOrder.current);
    drawDriver(displayedOrder.current);
  }

  const displayEdit = (delivery, type) => () => {
    const value = delivery[type];
    const { id, client_reference, package_description } = delivery;
    const { contact, comment } = value;
    const { formatted_address: address } = value.address;
    const isValid = false;
    const addressObject = {
      contact, comment, client_reference, package_description, address, isValid
    };
    setShowEdit(true);
    setShowEditDetails(type === "dropoff")
    setEditedAddressText(address);
    setEditedAddress(addressObject);
    editedDeliveryId.current = id;
    editedType.current = type;
  }

  return (
    <Container fluid id="ongoing-page">
      {state.ongoing && !state.ongoing.length && <div className="ongoing-empty">
        <img src="no-ongoing.svg" alt="no ongoing" />
        <h3>{t("noOngoingTitle")}</h3>
        <p>{t("noOngoingDescription")}</p>
        <Button variant="info" as={NavLink} to="/">{t("requestDelivery")}</Button>
      </div>}
      {state.ongoing && state.ongoing.length > 0 && < div >
        <div className="ongoing-mapcontainer">
          <div ref={mapContainer} className="ongoing-mapelement"></div>
        </div>
        <br />
        {state.ongoing.map((element, index) => <div className="ongoing-requestcard" key={index}>
          <Card className="ongoing-card" onClick={select(element.Job)}>
            <Card.Header className="ongoing-cardheader">
              <Row>
                <Col>
                  {t(getStatusProgress(element.Job).msg, {left: getStatusProgress(element.Job).timeProgress.timeLeft})}
                </Col>
                <Col xs="auto">
                  #{element.Job.id}
                </Col>
              </Row>
              <Row> <Col>
                <ProgressBar 
                  variant={getStatusProgress(element.Job).variant}
                  animated
                  now={getStatusProgress(element.Job).timeProgress.progress}
                  className="ongoing-progressbar"
                />
              </Col> </Row>
            </Card.Header>
            <Card.Body className="ongoing-cardbody">
              <Card.Text as="div">
                <Row>
                  <Col sm={1}>
                    <div className="ongoing-circlecontainer">
                      <div className="ongoing-circle ongoing-yellow"></div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      {element.Job.deliveries[0].pickup.contact.company_name}
                    </div>
                    <div>
                      {element.Job.deliveries[0].pickup.contact.firstname}
                      &nbsp;
                      {element.Job.deliveries[0].pickup.contact.lastname}
                    </div>
                    <div>
                      {element.Job.deliveries[0].pickup.address.formatted_address}
                    </div>
                  </Col>
                  <Col xs="auto">
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle}>
                        <FontAwesomeIcon
                          icon={faEllipsisH}
                          className="ongoing-deliverymenu"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu alignRight>
                        <Dropdown.Item eventKey="1" onClick={displayEdit(element.Job.deliveries[0], "pickup")}>
                          <FontAwesomeIcon icon={faPen} />
                          &nbsp;
                          {t("editDelivery")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                <br />
                { displayedId === element.Job.id && <div>
                  {element.Job.deliveries.map(delivery => ( <div key={delivery.id}>
                    <Row>
                      <Col sm={1}>
                        <div className="ongoing-circlecontainer">
                          <div className="ongoing-circle ongoing-green"></div>
                        </div>
                      </Col>
                      <Col>
                        <div className="container-flex">
                          <div>{delivery.dropoff.contact.company_name}</div>
                          <div>
                            {delivery.dropoff.contact.firstname}
                            &nbsp;
                            {delivery.dropoff.contact.lastname}
                          </div>
                          <div>
                            {delivery.dropoff.address.formatted_address}
                          </div>
                        </div>
                      </Col>
                      <Col xs="auto">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle}>
                            <FontAwesomeIcon
                              icon={faEllipsisH}
                              className="ongoing-deliverymenu"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu alignRight>
                            <Dropdown.Item eventKey="1" onClick={displayEdit(delivery, "dropoff")}>
                              <FontAwesomeIcon icon={faPen} />
                              &nbsp;
                              {t("editDelivery")}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2">
                              <RequestCanceller
                                destinations={[delivery.dropoff.address.formatted_address]}
                                postCancel={reloadRequests}
                                entity="deliveries"
                                entityId={delivery.id}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                                &nbsp;
                                {t("cancel")}
                              </RequestCanceller>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <br />
                  </div>)) }
                  {element.Job.status !== 'searching' &&
                    <Row>
                      <Col sm={1}>
                        <div className="ongoing-drivericoncircle">
                          <FontAwesomeIcon icon={faUser} size="2x" className="ongoing-drivericon"/>
                        </div>
                      </Col>
                      <Col>
                        <div className="container-flex">
                          <div>{element.Job.driver.display_name}</div>
                          <div>{element.Job.driver.phone}</div>
                        </div>
                      </Col>
                      <Col sm="auto">
                        <img
                          src={`icons/${element.Job.transport_type}.svg`}
                          alt={element.Job.transport_type}
                        />
                      </Col>
                    </Row>
                  }
                </div> }
              </Card.Text>
            </Card.Body>
            { displayedId === element.Job.id &&
              <Card.Footer>
                <Row>
                  <Col>{t("distance")}: {element.Job.distance.toFixed(2)} km</Col>
                  <Col xs="auto">
                    <RequestCanceller
                      destinations={element.Job.deliveries.map(d => d.dropoff.address.formatted_address)}
                      postCancel={reloadRequests}
                      entity="jobs"
                      entityId={element.Job.id}
                    >
                      <Button variant="outline-secondary" className="ongoing-cancelbtn">
                        {t("cancel")}
                      </Button>
                    </RequestCanceller>
                  </Col>
                   {state.user.UserConfig.ShowPricing &&<Col xs="auto">
                    <div className="ongoing-subtotal">
                      {t("subtotal")}: €{getPrice(element).price_tax_excluded}
                    </div>
                    <div>
                      {t("taxedPrice")}: €{getPrice(element).price_tax_included}
                    </div>
                  </Col>}
                </Row>
              </Card.Footer>
            }
          </Card>
          <br />
        </div>
        )}
      </div>}
      <RequestEdit
          editedAddress={editedAddress}
          orderId={displayedOrder.current.id}
          editedDeliveryId={editedDeliveryId.current}
          editedType={editedType.current}
          showEdit={showEdit}
          showEditDetails={showEditDetails}
          setShowEdit={setShowEdit}
          reloadRequests={reloadRequests}
          setEditedAddress={setEditedAddress}
          editedAddressText={editedAddressText}
        >
      </RequestEdit>
    </Container >
  )
};

export default Ongoing;
