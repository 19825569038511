import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  Switch,
  Route, Redirect, useRouteMatch,
} from "react-router-dom";

import AddressesSettings from "../AddressesSettings"
import LegalNotice from '../LegalNotice';

import "./Settings.css";

const Settings = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <Container>
      <br />
      <Row>
        <Col xs={3}>
          <ListGroup defaultActiveKey="#settings">
            <ListGroup.Item as={NavLink} to={`${path}/addresses`} className="settings-item">
              {t("addressBook")}
            </ListGroup.Item>
            <ListGroup.Item as={NavLink} to={`${path}/legal-notice`} className="settings-item" >
              {t("gcuAndLegalNotice")}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={9}>
          <Card className="settings-contentcard">
            <Switch>
              <Route path={path} exact>
                <Redirect to={`${path}/addresses`} />
              </Route>
              <Route path={`${path}/addresses`}>
                <AddressesSettings />
              </Route>
              <Route path={`${path}/legal-notice`}>
                <LegalNotice></LegalNotice>
              </Route>
            </Switch>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

Settings.defaultProps = {};

export default Settings;
