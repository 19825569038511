import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { getXLVehicleType } from '../../utilities/requests';

import "./OrderVehicle.css"


const isVehicleDisabled = ({ success, amount }) => {
  return !success || !amount.price_tax_excluded;
}

const titleIcon = "icons/tire-colored.svg";

const OrderVehicle = (props) => {
  const { t } = useTranslation();
  const defaultItem = { success: false, amount: props.defaultAmount };

  const vehiclesList = [{ vehicule_type: "truck" }]
  const shouldCollapse = props.collapsed && props.value.length > 0;

  return (
    <Fragment>
      <Container className="App-default-component">
        <Form className="ordervehicle-form" disabled={props.disabled}>
          <Row>
            <Col xs={1}>
              <img src={titleIcon} alt="title icon" />
            </Col>
            <Col>
              <h4>{t("vehicleTypeTitle")}</h4>
            </Col>
          </Row>
          {shouldCollapse && <Row className="ordervehicle-row">
            <Col xs={2}>
              <img src={`icons/${props.value}.svg`} alt={props.value} />
            </Col>
            <Col xs={6}>
              <div><strong> {t(props.value)} </strong></div>
            </Col>
            {props.showPrice && <Col xs={3} className="ordervehicle-price">
              <span>{props.prices[props.value].amount.price_tax_excluded.toFixed(2)} €</span>
            </Col>}
          </Row>}
          {!shouldCollapse && vehiclesList.map((vehicle, index) => <div key={index}>
            <br />
            <label className="ordervehicle-row" disabled={isVehicleDisabled(vehicle)}>
              <Col xs={2}>
                <img src={`icons/${vehicle.vehicule_type}.svg`} alt={vehicle.vehicule_type} />
              </Col>
              <Col xs={6}>
                <div><strong> {t(vehicle.vehicule_type)} </strong></div>
                <div className="ordervehicle-error"> {t((vehicle.error || "").split(": ")[1])} </div>
              </Col>
              {props.showPrice && <Col xs={3} className="ordervehicle-price">
                {vehicle.success && <span>{vehicle.amount.price_tax_excluded.toFixed(2)} €</span>}
              </Col>}
              <Col xs={1}>
                <Form.Check
                  type="radio"
                  checked
                />
              </Col>
            </label>
          </div>)
          }
        </Form>
      </Container>
    </Fragment>
  )
};

OrderVehicle.propTypes = {
  setter: PropTypes.func,
  value: PropTypes.string,
};

OrderVehicle.defaultProps = {};

export default OrderVehicle;
