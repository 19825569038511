import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageSubtitle from '../PageSubtitle';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { faEllipsisH, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';

import './RequestPage.css';
import { loadRequest } from '../../utilities/requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import RequestCanceller from '../RequestCanceller';
import RequestEdit from '../RequestEdit';
import { GlobalStateContext } from '../../state';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

dayjs.extend(customParseFormat);

const getLatLng = ({ latitude: lat, longitude: lng }) => ({ lat, lng });

const markerParam = (icon, locations) =>
  `icon:${window.location.origin}${icon}${locations}`;

const getPrice = ({ Job: { pricing }, PricingInfo }) => {
  if (PricingInfo) return PricingInfo.price;
  return pricing;
};

const RequestPage = () => {
  const [mode, setMode] = useState('view');
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [state] = useContext(GlobalStateContext);

  const [request, setRequest] = useState({ Job: { pricing: {}, distance: 0 } });
  const [mapImage, setMapImage] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditDetails, setShowEditDetails] = useState(true);
  const [editedAddress, setEditedAddress] = useState({});
  const [editedAddressText, setEditedAddressText] = useState('');
  const editedDeliveryId = useRef(null);
  const editedType = useRef(null);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  const reloadRequest = () => {
    loadRequest(id).then(setRequest);
  };

  const displayEdit = (delivery, type) => () => {
    const value = delivery[type];
    const { id, client_reference, package_description } = delivery;
    const { contact, comment } = value;
    const { formatted_address: address } = value.address;
    const isValid = false;
    const addressObject = {
      contact,
      comment,
      client_reference,
      package_description,
      address,
      isValid,
    };

    setShowEdit(true);
    setShowEditDetails(type === 'dropoff');
    setEditedAddressText(address);
    setEditedAddress(addressObject);
    editedDeliveryId.current = id;
    editedType.current = type;
  };

  useEffect(() => {
    loadRequest(id).then(setRequest);
  }, [id]);

  useEffect(() => {
    if (window.google && request.Job.id) {
      const pickupLocation = getLatLng(request.Job.deliveries[0].pickup);
      const pickupMarkerLocation = `|${pickupLocation.lat},${pickupLocation.lng}`;

      const dropoffMarkersLocations = request.Job.deliveries.reduce(
        (accum, delivery) => {
          const dropoffLocation = getLatLng(delivery.dropoff);
          return accum + `|${dropoffLocation.lat},${dropoffLocation.lng}`;
        },
        ''
      );

      const lats = request.Job.deliveries.reduce(
        (accum, delivery) => {
          const dropoffLocation = getLatLng(delivery.dropoff);
          accum.push(dropoffLocation.lat);
          return accum;
        },
        [pickupLocation.lat]
      );

      const lngs = request.Job.deliveries.reduce(
        (accum, delivery) => {
          const dropoffLocation = getLatLng(delivery.dropoff);
          accum.push(dropoffLocation.lng);
          return accum;
        },
        [pickupLocation.lng]
      );

      const sw = { lat: Math.min(...lats), lng: Math.min(...lngs) };
      const ne = { lat: Math.max(...lats), lng: Math.max(...lngs) };
      const bounds = new window.google.maps.LatLngBounds(sw, ne);
      const center = bounds.getCenter();
      const origin = 'https://maps.googleapis.com/maps/api/staticmap';
      const params = new URLSearchParams([
        ['center', `${center.lat()},${center.lng()}`],
        ['size', '300x300'],
        ['markers', markerParam('/pickup-marker.png', pickupMarkerLocation)],
        [
          'markers',
          markerParam('/dropoff-marker.png', dropoffMarkersLocations),
        ],
        ['key', process.env.REACT_APP_GOOGLE_API_KEY],
      ]);
      const imageUrl = `${origin}?${params.toString()}`;
      setMapImage(imageUrl);
    }
  }, [request]);

  const statusTools = (job) => {
    switch (job.status) {
      case 'scheduled':
        return (
          <div className='requestpage-tools'>
            <RequestCanceller
              entityId={job.id}
              entity='jobs'
              destinations={job.deliveries.map(
                (d) => d.dropoff.address.formatted_address
              )}
              postCancel={reloadRequest}
            >
              <Button variant='outline-danger'>{t('cancel')}</Button>
            </RequestCanceller>
          </div>
        );
      default:
        return '';
    }
  };

  const formatReason = (s) => {
    if (typeof s !== 'string') {
      return '';
    }
    s = s.replace('_', ' ');
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    title: 'Livraison ' + id,
    //    copyStyles: true,
    // onBeforePrint: () => {
    //   setMode('print');
    // },
    // onAfterPrint: () => {
    //   setMode('view');
    // },
    onAfterPrint: () => {
      setIsReadyToPrint(false);
    },
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName('html')[0];
        try {
          // Configurer les options pour html2pdf.js
          const options = {
            margin: 0.5,
            filename: `Livraison_${id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
          };
          // Convertir le contenu HTML en PDF et le télécharger
          html2pdf().set(options).from(html).save();
          toast.success('PDF généré avec succès', {
            position: 'bottom-right',
          });
        } catch (error) {
          console.error('Erreur lors de la génération du PDF:', error);
        }
      }
    },
  });

  const initialPrint = () => {
    setMode('print');
    setIsReadyToPrint(true);
  };

  useEffect(() => {
    if (isReadyToPrint) {
      handlePrint();
    } else {
      setMode('view');
    }
  }, [isReadyToPrint]);

  return (
    <div>
      {mode === 'print' && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 1)', // Semi-transparent white
            zIndex: 9999, // Ensure it covers everything else
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner animation='border' role='status'></Spinner>
        </div>
      )}

      <div ref={componentRef}>
        <Container fluid>
          <Row>
            <div className='col-md-6 text-center'>
              <PageSubtitle
                canGoBack={mode !== 'print'}
                tools={statusTools(request.Job)}
              >
                <h6>
                  {t('delivery')} #{id}
                </h6>
              </PageSubtitle>
            </div>
            <div className='col-md-6 bg-white text-center'>
              <div className='pt-2'>
                {mode === 'view' && (
                  <Button className='success' onClick={initialPrint}>
                    Générer pdf
                  </Button>
                )}
              </div>
            </div>
          </Row>
          <br />
          <Row>
            <Col
              xs={{
                span: mode === 'print' ? 12 : 10,
                offset: mode === 'print' ? 0 : 1,
              }}
            >
              {['scheduled', 'canceled'].includes(request.Job.status) && (
                <Card>
                  <Card.Body>
                    {request.Job.status === 'scheduled' && (
                      <div>
                        <span className='requestpage-fade'>
                          {t('requestIsScheduled')}
                        </span>
                        <strong>
                          {dayjs(request.Job.pickup_at).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </strong>
                      </div>
                    )}
                    {request.Job.status === 'canceled' && (
                      <div>
                        <span className='requestpage-fade'>
                          {t('requestIsCanceled')}{' '}
                          {t(
                            formatReason(
                              request.Job.deliveries[0].cancellation.reason_key
                            )
                          )}
                        </span>
                        {request.Job.deliveries[0].cancellation.comment && (
                          <span className='requestpage-fade'>
                            : {request.Job.deliveries[0].cancellation.comment}
                          </span>
                        )}
                      </div>
                    )}
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              md={{
                span: mode === 'print' ? 12 : 3,
                offset: mode === 'print' ? 0 : 1,
              }}
            >
              <Card>
                <Card.Body>
                  <Container>
                    {['scheduled'].includes(request.Job.status) && (
                      <Row>
                        <Col>
                          <strong>
                            {dayjs(request.Job.pickup_at).format('DD/MM/YYYY')}
                          </strong>
                        </Col>
                        <Col>
                          <strong>
                            {dayjs(request.Job.pickup_at).format('HH:mm')}
                          </strong>
                        </Col>
                      </Row>
                    )}
                    {['finished', 'canceled', 'expired'].includes(
                      request.Job.status
                    ) && (
                      <Row>
                        <Col>
                          <strong>
                            {dayjs(request.Job.ended_at).format('DD/MM/YYYY')}
                          </strong>
                        </Col>
                      </Row>
                    )}
                    {request.Job.distance > 0 && (
                      <Row>
                        <Col></Col>
                        <Col>
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            size='sm'
                            color='#11a3eb'
                          />
                          &nbsp;&nbsp;&nbsp;
                          <span className='requestpage-fade'>
                            {request.Job.distance.toFixed(2)} km
                          </span>
                        </Col>
                      </Row>
                    )}
                    <br />
                    <Row>
                      <Col>
                        {mapImage && mode === 'view' && (
                          <img
                            className='requestpage-image'
                            src={mapImage}
                            alt='map'
                          />
                        )}
                      </Col>
                    </Row>
                    <br />
                    {state.user.UserConfig.ShowPricing && (
                      <span>
                        <Row className='requestpage-fade'>
                          <Col>{t('subtotal')}</Col>
                          <Col xs='auto'>
                            €{getPrice(request).price_tax_excluded}
                          </Col>
                        </Row>
                        <Row className='requestpage-fade'>
                          <Col>{t('tax')}</Col>
                          <Col xs='auto'>€{getPrice(request).tax_amount}</Col>
                        </Row>
                        <Row className='requestpage-fade requestpage-pricetotal'>
                          <Col>{t('total')}</Col>
                          <Col xs='auto'>
                            €{getPrice(request).price_tax_included}
                          </Col>
                        </Row>{' '}
                      </span>
                    )}
                  </Container>
                </Card.Body>
              </Card>
              <br />
              {request.Job.driver &&
                request.Job.driver.display_name.length > 0 && (
                  <Card>
                    <Card.Body>
                      <Container>
                        <Row>
                          <Col>
                            <strong>{request.Job.driver.display_name}</strong>
                          </Col>
                          <Col className='requestpage-tools'>
                            <img
                              src={`/icons/${request.Job.transport_type}.svg`}
                              alt={request.Job.transport_type}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>
                )}
            </Col>
            <Col
              md={{
                span: mode === 'print' ? 12 : 7,
              }}
            >
              <Card>
                <Card.Body>
                  <Container>
                    <Row>
                      <Col xs='auto'>
                        <img
                          src='/pickup-icon.svg'
                          width='32'
                          alt='icon for pickup'
                        />
                      </Col>
                      <Col xs='auto' className='requestpage-yellow'>
                        {' '}
                        <h5> {t('pickup')} </h5>{' '}
                      </Col>
                      <Col>
                        {request.Job.pickup_at &&
                          ['scheduled', 'canceled'].includes(
                            request.Job.status
                          ) && (
                            <Badge pill>
                              {dayjs(request.Job.pickup_at).format('HH:mm')}
                            </Badge>
                          )}
                        {request.Job.deliveries &&
                          ['finished'].includes(request.Job.status) && (
                            <Badge pill>
                              {dayjs(
                                request.Job.deliveries[0].picked_at
                              ).format('HH:mm')}
                            </Badge>
                          )}
                      </Col>
                      {request.Job.deliveries &&
                        ['scheduled'].includes(request.Job.status) && (
                          <Col xs='auto'>
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle}>
                                <FontAwesomeIcon
                                  icon={faEllipsisH}
                                  className='ongoing-deliverymenu'
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu alignRight>
                                <Dropdown.Item
                                  eventKey='1'
                                  onClick={displayEdit(
                                    request.Job.deliveries[0],
                                    'pickup'
                                  )}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                  &nbsp;
                                  {t('editDelivery')}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        )}
                    </Row>
                    <br />
                    {request.Job.deliveries && (
                      <div>
                        <Row className='requestpage-fade'>
                          <Col>
                            {request.Job.deliveries[0].pickup.contact.firstname}
                            &nbsp;
                            {request.Job.deliveries[0].pickup.contact.lastname}
                          </Col>
                        </Row>
                        <Row className='requestpage-fade'>
                          <Col>
                            {
                              request.Job.deliveries[0].pickup.contact
                                .company_name
                            }
                          </Col>
                        </Row>
                        <Row className='requestpage-fade'>
                          <Col>
                            {
                              request.Job.deliveries[0].pickup.address
                                .formatted_address
                            }
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Container>
                </Card.Body>
              </Card>
              <br />
              {request.Job.deliveries &&
                request.Job.deliveries.map((delivery, index) => (
                  <div key={delivery.id}>
                    <Card>
                      <Card.Body>
                        <Container>
                          <Row>
                            <Col xs='auto'>
                              <img
                                src='/dropoff-icon.svg'
                                width='32'
                                alt='icon for dropoff'
                              />
                            </Col>
                            <Col xs='auto' className='requestpage-green'>
                              {' '}
                              <h5> {t('dropoff')} </h5>{' '}
                            </Col>
                            <Col>
                              {delivery.delivered_at &&
                                delivery.status === 'delivered' && (
                                  <Badge pill>
                                    {dayjs(delivery.delivered_at).format(
                                      'HH:mm'
                                    )}
                                  </Badge>
                                )}
                            </Col>
                            {['scheduled'].includes(request.Job.status) && (
                              <Col xs='auto'>
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle}>
                                    <FontAwesomeIcon
                                      icon={faEllipsisH}
                                      className='ongoing-deliverymenu'
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu alignRight>
                                    <Dropdown.Item
                                      eventKey='1'
                                      onClick={displayEdit(delivery, 'dropoff')}
                                    >
                                      <FontAwesomeIcon icon={faPen} />
                                      &nbsp;
                                      {t('editDelivery')}
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>
                                      <RequestCanceller
                                        destinations={[
                                          delivery.dropoff.address
                                            .formatted_address,
                                        ]}
                                        postCancel={reloadRequest}
                                        entity='deliveries'
                                        entityId={delivery.id}
                                      >
                                        <FontAwesomeIcon icon={faTimes} />
                                        &nbsp;
                                        {t('cancel')}
                                      </RequestCanceller>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            )}
                          </Row>
                          <br />
                          <div className='requestpage-signature'>
                            {delivery.proof.signature_url && (
                              <img
                                className='requestpage-image'
                                src={delivery.proof.signature_url}
                                alt='Signature'
                              />
                            )}
                            {!delivery.proof.signature_url && (
                              <small>{t('noSignature')}</small>
                            )}
                          </div>
                          <Row className='requestpage-fade'>
                            <Col>
                              {delivery.dropoff.contact.firstname}
                              &nbsp;
                              {delivery.dropoff.contact.lastname}
                            </Col>
                          </Row>
                          <Row className='requestpage-fade'>
                            <Col>{delivery.dropoff.contact.company_name}</Col>
                          </Row>
                          <Row className='requestpage-fade'>
                            <Col>
                              {delivery.dropoff.address.formatted_address}
                            </Col>
                          </Row>
                          <br />
                          {delivery.client_reference && (
                            <Row className='requestpage-fade requestpage-orderdetails'>
                              <Col>
                                {t('orderId')}: &nbsp;
                                {delivery.client_reference}
                              </Col>
                            </Row>
                          )}
                          {delivery.package_description && (
                            <Row className='requestpage-fade requestpage-orderdetails'>
                              <Col>
                                {t('orderInfos')}: &nbsp;
                                {delivery.package_description}
                              </Col>
                            </Row>
                          )}
                          {request.PricingInfo && (
                            <Row className='requestpage-fade requestpage-pricinginfo'>
                              {request.PricingInfo.deliveries &&
                                request.PricingInfo.deliveries.length && (
                                  <Col>
                                    {request.PricingInfo.deliveries[index]
                                      .pricing_criteria && (
                                      <Fragment>
                                        <b>{t('pricingCriterias')}:</b>
                                        <br />
                                        {Object.keys(
                                          request.PricingInfo.deliveries[index]
                                            .pricing_criteria
                                        ).map((key) => (
                                          <Fragment key={`pc-${key}-${index}`}>
                                            <small>
                                              {key}: &nbsp;
                                              {
                                                request.PricingInfo.deliveries[
                                                  index
                                                ].pricing_criteria[key]
                                              }
                                              &nbsp;
                                              {key === 'distance' ? 'km' : ''}
                                            </small>
                                            <br />
                                          </Fragment>
                                        ))}
                                      </Fragment>
                                    )}
                                    {request.PricingInfo.deliveries[index]
                                      .options && (
                                      <Fragment>
                                        <b>{t('options')}:</b> &nbsp;
                                        <small>
                                          {request.PricingInfo.deliveries[
                                            index
                                          ].options.join(',')}
                                        </small>
                                      </Fragment>
                                    )}
                                  </Col>
                                )}
                            </Row>
                          )}
                        </Container>
                      </Card.Body>
                    </Card>
                    <br />
                  </div>
                ))}
            </Col>
          </Row>
          <RequestEdit
            editedAddress={editedAddress}
            orderId={request.Job.id}
            editedDeliveryId={editedDeliveryId.current}
            editedType={editedType.current}
            showEdit={showEdit}
            showEditDetails={showEditDetails}
            setShowEdit={setShowEdit}
            reloadRequests={reloadRequest}
            setEditedAddress={setEditedAddress}
            editedAddressText={editedAddressText}
          ></RequestEdit>
        </Container>
      </div>
    </div>
  );
};

export default RequestPage;
