import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import "./OrderOptions.css"

const OrderOptions = (props) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container>
        <Form className="orderoptions-form" disabled={props.disabled}>
          <Row>
            <Col>
              <h4>{t("options")}</h4>
            </Col>
          </Row>
          {props.list.map((option, index) => <div key={index}>
            <br />
            <Row as="label" className="orderoptions-row">
              <Col xs={11}>
                {t(option)}
              </Col>
              <Col xs={1}>
                <Form.Check
                  type="checkbox"
                  checked={props.value.includes(option)}
                  onChange={(e) => props.setter(option, e.target.checked)}
                />
              </Col>
            </Row>
          </div>)
          }
        </Form>
      </Container>
    </Fragment>
  )
};

OrderOptions.propTypes = {
  setter: PropTypes.func,
  list: PropTypes.array,
  value: PropTypes.array,
};

OrderOptions.defaultProps = {};

export default OrderOptions;
