import React, { createContext, useReducer, useEffect, useState } from 'react';

import reducer from './reducer';
import { initialState } from './constants';
import { gMapsUrl } from "../utilities/map";

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [googleLoaded, setGoogleLoaded] = useState(false);

  useEffect(() => {
    let script = document.createElement("script")
    script.type = "text/javascript";
    script.onload = () => {
      setGoogleLoaded(true);
    }
    script.src = gMapsUrl;
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  return (
    <GlobalStateContext.Provider value={[state, { dispatch, googleLoaded }]}>
      {children}
    </GlobalStateContext.Provider>
  );
}
