import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import './DynamicInput.css';

const typesElements = {
  input: (props) => (
    <>
      <Form.Control
        onChange={props.onChange}
        readOnly={props.readOnly}
        disabled={props.readOnly}
        value={props.value}
      />
      {(/\s/g.test(props.value) || isNaN(props.value)) && <Form.Text className="dynamicinput-invalidmsg">{props.t("wrongNumberFormat")}</Form.Text>}
    </>
  ),
  select: (props) => <Form.Control
    as="select"
    onChange={props.onChange}
    readOnly={props.readOnly}
    disabled={props.readOnly}
    value={props.value}
    >
      <option value="" disabled hidden></option>
      {props.options.map(option => <option
        key={option}
        value={option}
        >{option}</option>)}
  </Form.Control>
}

const DynamicInput = (props) => {
  const { t } = useTranslation();

  return (
    <Form.Group controlId={props.controlId}>
      <Form.Label className="dynamicinput-label">{props.name}</Form.Label>
      {typesElements[props.type]({...props, t})}
    </Form.Group>
  )
};

DynamicInput.propTypes = {
  controlId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["input", "select"]).isRequired,
  options: PropTypes.array,
};

DynamicInput.defaultProps = {
  options: [],
};

export default DynamicInput;
