import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { cancelRequest } from "../../utilities/requests";


import "./RequestCanceller.css";
import { useTranslation } from "react-i18next";

const cancellationreason = [
  "address_error",
  "courier_issue",
  "customer_cancellation_requested",
  "duplicate_job",
  "incorrect_package",
  "no_supply",
  "other",
  "package_damaged",
  "package_not_ready",
  "pu_closed",
  "technical_issue",
];

const CancelReason = (props) => {
  const { t } = useTranslation();

  const [showCancelReason, setShowCancelReason] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancellationError, setCancellationError] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelComment, setCancelComment] = useState("");
  const entity = useRef(props.entity || "jobs");
  const endReasonsRef = useRef(null);

  const handleClose = (e) => {
    e.stopPropagation();
    setShowCancelReason(false);
    setCancellationError(false);
  };

  const handleHide = (e) => {
    setShowCancelReason(false);
  };

  const handleShow = (e) => {
    e.stopPropagation();
    setShowCancelReason(true);
  };

  const updateReason = (reason) => (e) => {
    e.stopPropagation();
    setCancelReason(reason);
  };

  const updateComment = (e) => {
    setCancelComment(e.target.value);
  };

  const cancel = async (e) => {
    e.stopPropagation();
    try {
      setIsCancelling(true);
      await cancelRequest(
        entity.current,
        props.entityId,
        cancelReason,
        cancelComment
      );
      if (props.postCancel) props.postCancel();
      setShowCancelReason(false);
    } catch (error) {
      setCancellationError(true);
    } finally {
      setIsCancelling(false);
      setCancelReason("");
    }
  };

  const canSubmit = cancelReason.length > 0;
  const submitBtnDisabled = !canSubmit || isCancelling;

  const scrollToBottom = () => {
    endReasonsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (cancelReason === "other") {
      scrollToBottom();
    }
  }, [cancelReason]);

  return (
    <div>
      {!isCancelling && <div onClick={handleShow}>{props.children}</div>}
      {isCancelling && <FontAwesomeIcon icon={faSpinner} pulse />}

      <Modal
        show={showCancelReason}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onHide={handleHide}
      >
        <Modal.Header>
          <div className="requestcanceller-header">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="2x"
              color="#99a5b3"
            />
            <div className="requestcanceller-alert">
              {!cancellationError && t("cancelAlert")}
              {cancellationError && t("cantCancel")}
            </div>
            <div className="requestcanceller-address">
              {props.destinations.map((destination, index) => (
                <p key={index}>{destination}</p>
              ))}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="requestcanceller-body">
          <Container>
            <br />
            <Row>{t("cancelHelp")}</Row>
            <br />
            {cancellationreason.map((reason, index) => (
              <Row as="label" className="requestcanceller-label" key={index}>
                <Col xs={11}>{t(reason)}</Col>
                <Col xs={1}>
                  <Form.Check
                    type="radio"
                    checked={cancelReason === reason}
                    onChange={updateReason(reason)}
                  />
                </Col>
              </Row>
            ))}
            {cancelReason === "other" && (
              <Row as="label" className="requestcanceller-label">
                <Form.Control
                  className="requestcanceller-comment"
                  as="textarea"
                  size="sm"
                  placeholder={t("cancelComment")}
                  value={cancelComment}
                  onChange={updateComment}
                />
              </Row>
            )}
            <div ref={endReasonsRef} />
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <div className="requestcanceller-footer">
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              disabled={isCancelling}
            >
              {!isCancelling && t("back")}
              {isCancelling && <FontAwesomeIcon icon={faSpinner} pulse />}
            </Button>
            {!cancellationError && (
              <Button
                variant="danger"
                onClick={cancel}
                disabled={submitBtnDisabled}
              >
                {!isCancelling && t("yesCancel")}
                {isCancelling && <FontAwesomeIcon icon={faSpinner} pulse />}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

CancelReason.propTypes = {
  destinations: PropTypes.array.isRequired,
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  postCancel: PropTypes.func,
};

export default CancelReason;