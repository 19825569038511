export const gMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&v=weekly&language=fr`

export const initMap = (mapRef, containerRef) => {
  const mapOptions = {
    center: { lat: 48.856613, lng: 2.352222 }, // Paris
    zoom: 15, // Streets Level
    mapTypeControl: false,
    mapTypeId: 'roadmap',
    streetViewControl: false,
    fullscreenControl: false,
    styles: ["administrative", "poi", "transit"].map(f => ({ featureType: f, stylers: [{ visibility: "off" }] })),
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_CENTER,
    },
  }
  mapRef.current = new window.google.maps.Map(containerRef.current, mapOptions);
}

export const getAddressLocation = address => new Promise((resolve, reject) => {
  try {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (result) => {
      if(!result || !result.length) return reject("No Result");
      resolve(result[0].geometry.location);
    })
  } catch (error) {
    reject(error)
  }
})

export const getPlaceLocation = (placeId, map) => new Promise((resolve, reject) => {
  try {
    const places = new window.google.maps.places.PlacesService(map);
    places.getDetails({ placeId }, (result) => {
      if(!result) reject("No Result");
      resolve(result.geometry.location)
    })
  } catch (error) {
    reject(error)
  }
})

export const infoWindowTemplate = (distance, index) => `
  <div id="content">
    ${index != null ? `<p>Livraison ${index + 1} : </p>` : ""}
    <h6>${parseFloat(distance).toFixed(2)} km</h6>
  </div>
`

export const getDistance = (origin, destination, travelMode, withRoute = false) =>
  new Promise(resolve => {
    try {
      let fastResult = null;

      if (!origin.length || !destination.length) return resolve({distance: "0", directionResult: fastResult});
      const params = {
        origin,
        destination,
        travelMode,
        avoidHighways: true,
        provideRouteAlternatives: true,
      }
      const ds = new window.google.maps.DirectionsService()
      ds.route(params, (response, status) => {
        if (status !== "OK") return resolve({distance: "0", directionResult: fastResult})
        const distances = response.routes.map(r => r.legs[0].distance.value);
        const distance = Math.min(...distances);

        if (withRoute){
          fastResult = {
            ...response,
            routes: [response.routes.find(r => r.legs[0].distance.value === distance)],
          };
        }

        return resolve({ distance: `${distance / 1000}`, directionResult: fastResult})
      })
    } catch (error) { }
  })
