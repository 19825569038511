import { toast } from 'react-toastify';

export const UPDATE_STATE = "UPDATE_STATE";
export const LOGOUT = "LOGOUT";

export const initialState = {
  addresses: [],
  user: {},
};

export const toastOptions = {
  position: toast.POSITION.BOTTOM_CENTER,
  pauseOnHover: false,
  hideProgressBar: true,
}
