import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import AddressListItem from "../AddressListItem";
import Address from '../Address';
import PageSpinner from "../PageSpinner"
import { GlobalStateContext } from '../../state';
import { loadAddresses, sendAddress, editAddress, deleteAddress } from '../../utilities/requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { toastOptions } from '../../state/constants';

import 'react-toastify/dist/ReactToastify.css';

const emptyContact = { firstname: "", lastname: "", phone: "", email: "" };

const emptyAddress = { address: "", contact: emptyContact, comment: "", }

const AddressesSettings = () => {
  const { t } = useTranslation();

  const [state, { dispatch }] = useContext(GlobalStateContext);
  const [show, setShow] = useState(false);
  const [displayedAddress, setDisplayedAddress] = useState(emptyAddress);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const submitAction = useRef(sendAddress);

  const handleClose = () => setShow(false);

  const openNew = () => {
    setShow(true);
    submitAction.current = sendAddress
    setDisplayedAddress(emptyAddress)
  }

  const displayAddress = (address) => {
    setShow(true);
    submitAction.current = editAddress;
    setDisplayedAddress(address)
  }

  const submit = async () => {
    try {
      setIsSubmitting(true);
      await submitAction.current(displayedAddress);
      handleClose();
      loadAddresses(dispatch);
    } catch (error) {
      toast.error("Une erreur est survenue", toastOptions);
    } finally {
      setIsSubmitting(false);
    }
  }

  const deleteDisplayedAddress = async () => {
    try {
      setIsSubmitting(true);
      await deleteAddress(displayedAddress.id);
      handleClose();
      loadAddresses(dispatch);
    } catch (error) {
      toast.error("Une erreur est survenue", toastOptions);
    } finally {
      setIsSubmitting(false);
    }
  }

  const reload = useCallback(async () => {
    try {
      setIsLoading(true)
      await loadAddresses(dispatch)
    } catch (error) {
      toast.error("Une erreur est survenue", toastOptions);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch])

  useEffect(() => {
    reload();
  }, [reload])

  const formattedSaved = state.addresses.map(a => ({
    type: "saved",
    title: `${a.contact.firstname || ""} ${a.contact.lastname || ""}`,
    subtitle: a.address,
    value: a,
  }))

  const canSubmit = displayedAddress.address.length > 0 && !isSubmitting && displayedAddress.isValid;

  return (
    <div>

      <Container>
        <br />
        <Row>
          <Col> <h4> {t("addressBook")} </h4> </Col>
          <Col xs={4}>
            <Button variant="info" onClick={openNew}>{t("addNewAddress")}</Button>
          </Col>
        </Row>
        <br />
        {isLoading && <PageSpinner></PageSpinner>}
        {!isLoading && formattedSaved.map((address, index) =>
          <Row key={index}>
            <Col>
              <AddressListItem
                title={address.title}
                subtitle={address.subtitle}
                type={address.type}
                onClick={() => { displayAddress(address.value) }}
              ></AddressListItem>
            </Col>
          </Row>
        )}
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("addressDetails")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Address
            id="pickup-component"
            query={displayedAddress}
            setter={setDisplayedAddress}
            address={t("pickup")}
            illustration="/pickup-illustration.svg"
            hideSaveOption={true}
            hideSavedAddresses={true}
            noTitle
          />
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col xs={8}>
                {displayedAddress.id && <Button variant="danger" onClick={deleteDisplayedAddress}>
                  {isSubmitting && <FontAwesomeIcon icon={faSpinner} pulse />}
                  {!isSubmitting && t("delete")}
                </Button>}
              </Col>
              <Col xs={4}>
                <Button variant="outline-secondary" onClick={handleClose}>
                  {isSubmitting && <FontAwesomeIcon icon={faSpinner} pulse />}
                  {!isSubmitting && t("cancel")}
                </Button>
                &nbsp;
                <Button variant="info" onClick={submit} disabled={!canSubmit}>
                  {isSubmitting && <FontAwesomeIcon icon={faSpinner} pulse />}
                  {!isSubmitting && t("submit")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <ToastContainer transition={Slide} />
    </div>
  )
};

AddressesSettings.defaultProps = {};

export default AddressesSettings;
