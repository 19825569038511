export default {
  translation: {
    newDelivery: "Nuova consegna",
    delivery: "Consegna",
    delivery_plural: "Consegne",
    address: "Indirizzo",
    address_plural: "Indirizzi",
    addressDetails: "Dettagli dell'indirizzo",
    addressBook: "Rubrica",
    addNewAddress: "Aggiungi un nuovo indirizzo",
    pickup: "Ritiro",
    dropoff: "Deposito",
    firstName: "Nome",
    lastName: "Cognome",
    searchAddress: "Ricerca un indirizzo",
    phone: "Telefono",
    invalidPhone: "Numero non valido",
    missingPhoneLength: "Mancano {{number}} numeri",
    email: "E-mail",
    invalidEmail: "Indirizzo email non valido",
    courierDetails: "Dettagli corriere",
    detailsPlaceholder: "Piano o altre informazioni aggiuntive",
    orderDetails: "Dettagli dell'ordine",
    orderId: "Numero dell'ordine",
    orderInfos: "Contenuti dell'ordine",
    distance: "Distanza",
    submitRequest: "Ordinare",
    for: "per",
    creatingRequest: "Creazione dell'ordine",
    vehicleTypeTitle: "Seleziona un veicolo",
    walk: "A piedi",
    bike: "Bici",
    motorbike: "Moto",
    car: "Auto",
    cargobike: "Cargobike",
    van: "Van",
    truck: "Camion",
    orderDateTitle: "Quando?",
    now: "Adesso",
    later: "Più tardi",
    date: "Data",
    timeSlot: "Fascia oraria",
    requestDelivery: "Richiesta di consegna",
    ongoing: "In corso",
    scheduled: "Programmate",
    history: "Cronologia",
    settings: "Impostazioni",
    credit: "Credito",
    logout: "Esci",
    login: "Entra",
    "Unauthorized, credentials not valid":
      "Non autorizzato, login o password sbagliati",
    username: "Login",
    password: "Password",
    loginTitle: "Connettiti a XL EDS",
    noOngoingTitle: "Nessuna consegna in corso",
    noOngoingDescription: "Programma la tua prima consegna ora",
    searching: "Stiamo cercando un corriere",
    in_progress: "Consegna entro {{left}} minuti",
    noScheduledTitle: "Nessuna consegna programmata",
    noScheduledDescription: "Le tue consegne programmate appariranno qui",
    rating: "Valutazione",
    invoice: "Fattura",
    downloadInvoice: "Scarica la fattura",
    price: "Prezzo",
    noHistoryTitle: "Nessuno storico",
    noHistoryDescription: "Non hai richiesto nessuna consegna",
    saveAddressLabel: "Salva l'indirizzo",
    noNameAddress: "Indirizzo senza nome cliente",
    addressAlreadySaved: "Questo indirizzo è già in rubrica",
    "The address can't be geocoded":
      "Questo indirizzo non è abbastanza preciso",
    "This location is out of range":
      "Ci dispiace, XL EDS non può consegnare a questo indirizzo",
    "Contact phone is required":
      "Questo indirizzo non è abbastanza preciso. Inserisci un numero di telefono",
    submit: "Iscrizione",
    cancel: "Annulla",
    yesCancel: "Si annullare",
    cancelled: "Annullato",
    cancelAlert: "Stai per annullare una consegna a",
    back: "Indietro",
    delete: "Cancella",
    options: "Opzioni",
    pricingCriterias: "Criteri delle tariffe",
    successToast: "Consegna creata con successo, reindirizzamento in corso..",
    cancelHelp:
      "Aiutaci a migliorare i servizi di XL EDS e dicci la ragione dell'annullamento",
    cantCancel: "Impossibile annullare questa consegna",
    address_error: "Errore di indirizzo",
    courier_issue: "Annullamento causa problema del corriere",
    customer_cancellation_requested: "Annullamento dal cliente",
    duplicate_job: "Richiesta duplicata",
    incorrect_package: "Scatola errata",
    no_supply: "Nessun corriere disponibile",
    other: "Altro",
    package_damaged: "Pacchetto danneggiato",
    package_not_ready: "Pacchetto non pronto",
    pu_closed: "Punto di ritiro chiuso",
    technical_issue: "Problema tecnico",
    subtotal: "Subtotale",
    taxedPrice: "Totale (con tasse)",
    tax: "Tasse",
    total: "Totale",
    requestIsScheduled: "La consegna è stata programmata, tieniti pronto a ",
    requestIsCanceled: "Hai annullato questa consegna.",
    priceNotSupported: "Queste opzioni non corrispondono a nessun prezzo",
    validatingAddress: "Stiamo validando l'indirizzo",
    clientReference: "Referenza cliente",
    filterByClientRef: "Filtrare per referenza cliente",
    filter: "Filtra",
    "A destination address is not in a valid delivery area":
      "La destinazione non è valida",
    "Unable to save record": "Errore di registrazione",
    tax_excluded: "Senza tasse",
    tax_included: "Tasse incluse",
    noHistorySearchResult: "Nessun risultato di ricerca",
    reloadHistory: "Ricarica lo storico",
    addDropOff: "Aggiungi una consegna",
    editDelivery: "Modifica la consegna",
    otherDeliveriesCount: "(+{{count}} altra consegna)",
    otherDeliveriesCount_plural: "(+{{count}} altre consegne)",
    errorLoadingPricingAttributes:
      "Errore di caricamento del prezzo, verifica la configurazione del prezzo",
    cancelComment: "Commenta la tua esperienza",
    noSignature: "Nessuna firma",
    shortPending: "Cerchiamo il miglior corriere.",
    mediumPending: "Pazienta, stiamo continuando a cercare.",
    longPending: "Analizziamo le migliori opzioni possibili.",
    highPending: "Non annullare per non perfere la priorità!",
    veryHighPending: "Il nostro algoritmo continua a cercare una soluzione.",
    eternityPending:
      "Stiamo aggiornando i sistemi per trovare un corriere. Pazienta...",
    picking: "Ritiro tra {{left}} minuti.",
    pickupNow: "Ritiro ora.",
    delivering: "Consegna tra {{left}} minuti.",
    dropoffNow: "Consegna adesso.",
    "Insufficient funds for client  Please provide a Credit Card":
      "Fondo insufficiente per il cliente. Fornisci una carta di credito.",
    "context deadline exceeded (Client Timeout exceeded while awaiting headers)":
      " Tempo scaduto nell'attesa delle intestazioni.",
    "Same Order ID can't be used in multiple destinations":
      "Lo stesso numero d'ordine non può essere utilizzato per più destinazioni.",
    packageDescriptionAvailableCharacters:
      "Ti rimangono {{nbrAvailableCharacters}} caratteri",
    packageDescriptionLimitExceeded:
      "Hai superato il numero massimo di caratteri autorizzati",
    wrongNumberFormat: "Formato non corretto, inserisci un numero valido",
    searchInArray: "Cerca nella tabella",
  },
};
