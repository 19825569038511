import React, { useRef, useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import AddressListItem from "../AddressListItem";
import { GlobalStateContext } from "../../state";

import "./AddressInput.css";

const AddressInput = (props) => {
  const { t } = useTranslation();
  const [state, { googleLoaded }] = useContext(GlobalStateContext);
  const [autocomplete, setAutocomplete] = useState([]);
  const [showPredictions, setShowPredictions] = useState(false);
  const [stagedValue, setStagedValue] = useState(props.value);
  const [selectedValue, setSelectedValue] = useState(props.value);
  const blurFromSelect = useRef(false);
  const service = useRef(null);
  const sessionToken = useRef(null);

  const onFocus = () => {
    setShowPredictions(true);
  };

  const onBlur = () => {
    setTimeout(() => {
      setShowPredictions(false);
      if (!blurFromSelect.current) {
        if (stagedValue.address === "") {
          setSelectedValue(stagedValue);
          props.onChange(stagedValue);
        } else {
          setStagedValue(selectedValue);
        }
      }
      blurFromSelect.current = false;
    }, 200);
  };

  const onSelect = (value) => {
    blurFromSelect.current = true;
    setStagedValue(value);
    setSelectedValue(value);
    setShowPredictions(false);
    props.onChange(value);
    sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
  };

  const displaySuggestions = (predictions, status) => {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK)
      return setAutocomplete([]);
    const formattedPreds = predictions.map((p) => ({
      type: "prediction",
      title: p.structured_formatting.main_text,
      subtitle: p.structured_formatting.secondary_text,
      value: { address: p.description, contact: {}, placeId: p.place_id },
    }));
    setAutocomplete(formattedPreds);
  };

  const formattedSaved = props.hideSaved
    ? []
    : state.addresses.map((a) => ({
        type: "saved",
        title: `${a.contact.firstname || ""} ${a.contact.lastname || ""}`,
        subtitle: a.address,
        value: a,
      }));

  const displayed = autocomplete.length ? autocomplete : formattedSaved;

  const handleChange = (input) => {
    const { placeId, ...pureStagedValue } = stagedValue;
    setStagedValue({ ...pureStagedValue, address: input });
    if (!input.length) {
      setAutocomplete([]);
      return;
    }
    const options = {
      input,
      sessionToken: sessionToken.current,
      componentRestrictions: { country: ["fr", "it", "es"] },
    };
    service.current.getPlacePredictions(options, displaySuggestions);
  };

  useEffect(() => {
    if (!window.google) return;
    sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
    service.current = new window.google.maps.places.AutocompleteService();
  }, [googleLoaded]);

  return (
    <div>
      <Form.Group className="addressinput-group">
        <Form.Control
          id={`${props.id}-input`}
          required
          autoComplete="off"
          className="addressinput-field"
          value={stagedValue.address}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={t("searchAddress")}
        />
        {showPredictions && (
          <div className="addressinput-predictions">
            {displayed.map((address, index) => (
              <AddressListItem
                key={index}
                title={address.title}
                subtitle={address.subtitle}
                type={address.type}
                onClick={() => {
                  onSelect(address.value);
                }}
              ></AddressListItem>
            ))}
          </div>
        )}
      </Form.Group>
    </div>
  );
};

AddressInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
};

AddressInput.defaultProps = {};

export default AddressInput;
