import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './UserMenuItem.css';

const UserMenuItem = ({ onClick, icon, children, ...rest }) => (
  <Dropdown.Item className="usermenuitem-item" onClick={onClick} {...rest}>
    <FontAwesomeIcon className="usermenuitem-icon" icon={icon} />
    <span className="usermenuitem-label"> {children} </span>
  </Dropdown.Item>
);

UserMenuItem.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
};

UserMenuItem.defaultProps = {};

export default UserMenuItem;
