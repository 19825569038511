import { fetchWrapper } from './auth';

export const loadPricingAttributes = async () => {
  const response = await fetchWrapper("/pricing/attributes")
  if (!response.ok) {
    throw await response.json();
  }
  const { response: unsortedAttributes } = await response.json()
  const pricingAttributes = (unsortedAttributes || []).sort((a, b) => a.name.localeCompare(b.name))
  return pricingAttributes;
}


export const getTruckPrice = async (body) => {
  const noPrice =  {
    deliveries: body.deliveries.map(pc => ({ ...pc, price: "0" })),
    price: {
      currency: "EUR",
      price_tax_excluded: 0,
      price_tax_included: 0,
      tax_amount: 0,
      tax_percentage: 0.2
    }
  }
  const response = await fetchWrapper("/pricing/price", {
    body: JSON.stringify(body),
    method: "POST"
  })
  const responseJson = await response.json();
  if (!response.ok || !responseJson.success) return noPrice;
  return {
    ...responseJson.response,
    price: {
      price_tax_excluded: parseFloat(responseJson.response.price.price_tax_excluded),
      price_tax_included: parseFloat(responseJson.response.price.price_tax_included),
      tax_amount: parseFloat(responseJson.response.price.tax_amount),
      tax_percentage: parseFloat(responseJson.response.price.tax_percentage)
    }
  };
}

export const getOtherVehiclesPrices = async (body) => {
  const data = {
    job: {
      ...body.job
    }
  };



  const response = await fetchWrapper("/price", {
    body: JSON.stringify(data),
    method: "POST"
  })
  const jsonResponse = await response.json();
  if (!response.ok) throw jsonResponse;
  const { response: prices } = jsonResponse;
  return prices.reduce((obj, v) => ({
    ...obj,
    amount: {
      currency: "EUR",
      price_tax_excluded: parseFloat(v.amount),
      price_tax_included: parseFloat(v.amount) * 1.2,
      tax_amount: parseFloat(v.amount) * 0.2,
      tax_percentage: 0.2
    },
  }), {})



}