import React, { Fragment, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { isAuthenticated } from '../../utilities/auth';
import { GlobalStateContext } from '../../state';
import PageSpinner from "../PageSpinner";

const content = (props, state) => {
  if(state.user.ID) return props.children;
  return <PageSpinner/>
} 

const PrivateRoute = (props) => {
  
  const [state] = useContext(GlobalStateContext);

  return <Fragment>
    {isAuthenticated() ? content(props, state) : <Redirect to="/login" />}
  </Fragment>
}


PrivateRoute.defaultProps = {};

export default PrivateRoute;
