export default {
  translation: {
    newDelivery: "Nuevo envío",
    delivery: "Envío",
    delivery_plural: "Envíos",
    address: "Dirección",
    address_plural: "Direcciones",
    addressDetails: "Detalles de la dirección",
    addressBook: "Libreta de direcciones",
    addNewAddress: "Añadir nueva dirección",
    pickup: "Recogida",
    dropoff: "Entrega",
    firstName: "Nombre",
    lastName: "Apellidos",
    searchAddress: "Buscar una dirección",
    phone: "Teléfono",
    invalidPhone: "Número de teléfono inválido",
    missingPhoneLength: "Faltan {{number}} números",
    email: "Correo electrónico",
    invalidEmail: "Correo electrónico inválido",
    courierDetails: "Información para el repartidor",
    detailsPlaceholder: "Información adicional",
    orderDetails: "Detalles del pedido",
    orderId: "Número de pedido",
    orderInfos: "Contenido del pedido",
    distance: "Distancia",
    submitRequest: "Solicitar",
    for: "para",
    creatingRequest: "Creación del pedido",
    vehicleTypeTitle: "Seleccione un vehículo",
    walk: "Walker",
    bike: "Bicicleta",
    motorbike: "Moto",
    car: "Coche",
    cargobike: "Cargobike",
    van: "Furgoneta",
    truck: "Camión",
    orderDateTitle: "Cuándo?",
    now: "Ahora",
    later: "Más tarde",
    date: "Fecha",
    timeSlot: "Franja horaria",
    requestDelivery: "Solicita un mensajero",
    ongoing: "En curso",
    scheduled: "Envíos programados",
    history: "Historial",
    settings: "Configuración",
    credit: "Saldo",
    logout: "Cerrar sesión",
    login: "Iniciar sesión",
    "Unauthorized, credentials not valid":
      "No autorizado, credenciales inválidas",
    username: "Usuario",
    password: "Contraseña",
    loginTitle: "Conectarse a XL EDS",
    noOngoingTitle: "No hay envíos en curso",
    noOngoingDescription: "Programe su primer envío ahora",
    searching: "Estamos buscando un repartidor",
    in_progress: "Entrega dentro de {{left}} minutos",
    noScheduledTitle: "Ningún envío programado",
    noScheduledDescription: "Sus envíos programados aparecerán aquí",
    rating: "Valoración",
    invoice: "Factura",
    downloadInvoice: "Descargar factura",
    price: "Precio",
    noHistoryTitle: "Sin histórico",
    noHistoryDescription: "No se ha solicitado ninguna entrega",
    saveAddressLabel: "Guardar esta dirección",
    noNameAddress: "Dirección sin nombre de cliente",
    addressAlreadySaved:
      "Esta dirección ya está guardada en la libreta de direcciones",
    "The address can't be geocoded": "Esta dirección no se puede localizar",
    "This location is out of range": "Esta dirección está fuera de cobertura",
    "Contact phone is required":
      "Esta dirección no es lo suficientemente precisa. Por favor, introduzca un número de teléfono",
    submit: "Registrar",
    cancel: "Cancelar",
    yesCancel: "Sí, cancelar",
    cancelled: "Cancelado",
    cancelAlert: "Está a punto de cancelar una entrega a",
    back: "Volver",
    delete: "Eliminar",
    options: "Opciones",
    pricingCriterias: "Tarifas",
    successToast: "Entrega creada con éxito, redirección en proceso...",
    cancelHelp:
      "Ayúdanos a mejorar los servicios de XL EDS y cuéntanos el motivo de la cancelación",
    cantCancel: "No es posible cancelar esta entrega",
    address_error: "Error de dirección",
    courier_issue: "Anulación por causa de un problema con el mensajero",
    customer_cancellation_requested: "Anulación por parte del cliente",
    duplicate_job: "Petición duplicada",
    incorrect_package: "Paquete incorrecto",
    no_supply: "Ningún repartidor disponible",
    other: "Otro",
    package_damaged: "Pedido dañado",
    package_not_ready: "Pedido no listo",
    pu_closed: "Punto de recogida cerrado",
    technical_issue: "Problema técnico",
    subtotal: "Subtotal",
    taxedPrice: "Total (con IVA)",
    tax: "IVA",
    total: "Total",
    requestIsScheduled: "La entrega ha sido programada para ",
    requestIsCanceled: "La entrega ha sido cancelada.",
    priceNotSupported: "Esta opción no corresponde a ningún precio",
    validatingAddress: "La dirección se está validando",
    clientReference: "Referencia del cliente",
    filterByClientRef: "Filtrar por referencia del cliente",
    filter: "Filtrar",
    "A destination address is not in a valid delivery area":
      "El destino no es válido",
    "Unable to save record": "Error de registro",
    tax_excluded: "Sin IVA",
    tax_included: "IVA incluido",
    noHistorySearchResult: "No hay resultados de búsqueda",
    reloadHistory: "Recargar el historial",
    addDropOff: "Añadir una entrega",
    editDelivery: "Modificar la entrega",
    otherDeliveriesCount: "(+{{count}} otras entregas)",
    otherDeliveriesCount_plural: "(+{{count}} otras entregas)",
    errorLoadingPricingAttributes:
      "Error de carga de precios, compruebe la configuración de precios",
    cancelComment: "Comenta tu experiencia",
    noSignature: "Sin firma",
    shortPending: "Buscando el mejor mensajero.",
    mediumPending: "Por favor, tenga paciencia, todavía seguimos buscando.",
    longPending: "Estamos analizando las mejores opciones posibles.",
    highPending: "No cancele, perderá su prioridad!",
    veryHighPending: "Nuestro algoritmo sigue buscando una solución.",
    eternityPending:
      "Estamos haciendo todo lo posible para encontrar un mensajero. Por favor, espere...",
    picking: "Recogida en {left}}} minutos.",
    pickupNow: "Recogida en curso.",
    delivering: "Entrega en {{left}} minutos.",
    dropoffNow: "Entrega en curso.",
    "Insufficient funds for client  Please provide a Credit Card":
      "Fondos insuficientes para el cliente. Por favor, facilite una tarjeta de crédito.",
    "context deadline exceeded (Client Timeout exceeded while awaiting headers)":
      " Se ha superado el tiempo de espera.",
    "Same Order ID can't be used in multiple destinations":
      "No se puede utilizar el mismo número de pedido para varios destinos.",
    packageDescriptionAvailableCharacters:
      "Quedan {{nbrAvailableCharacters}} caracteres",
    packageDescriptionLimitExceeded:
      "Ha superado el número máximo de caracteres permitido",
    wrongNumberFormat: "Formato incorrecto, debe introducir un número válido",
    searchInArray: "buscar en la tabla",
  },
};
