import { useTranslation } from 'react-i18next'
import {Col, Container, Row} from "react-bootstrap";

const LegalNotice = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <br />
            <Row>
                <Col> <h4> {t("generalConditions")} </h4> </Col>
            </Row>
            <br />
            <Row>
               <Col>
                   <a href="/CGU.pdf" rel="noreferrer" target="_blank" className="p-3">
                       {t("termsOfService")}
                   </a>
               </Col>
            </Row>
            <br/>
            <br/>
            <Row>
                <Col> <h4> {t("legalNotice")} </h4> </Col>
            </Row>
            <br />
            <Row>
               <Col>
                   <h6 className='p-3'><strong>EXPRESS DELIVERIES SERVICES</strong></h6>
                   <div className='p-3'>
                      {t("legalNoticeText").split('\n').map((text) => (<p>{text}</p>))}
                   </div>
               </Col>
            </Row>
        </Container>
    )
}

export default LegalNotice;